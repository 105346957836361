m n<template>
    <div class="user-wrapper">
        <a class="item finder" href="javascript:;" title="搜索" @mouseenter="$emit('toggle-search-drop')" @mouseleave="$emit('toggle-search-drop')"><i class="icon icon-search" /></a>
        <!-- <template v-if="userInfoObj"> -->
        <a class="item avatar-box" href="javascript:;" @click.stop="goUserCenter" @mouseenter="$emit('toggle-uc-drop')" @mouseleave="$emit('toggle-uc-drop')">
            <i v-if="!userInfoObj || Object.keys(userInfoObj).length === 0" class="avatar icon icon-person-normal"></i>
            <img v-else class="avatar" :src="userInfoObj.headimgurl || require('@a/img/history-img.png')" alt="">
            <i v-if="userInfoObj && userInfoObj.news!=='0'" class="icon">!</i>
        </a>
        <!-- </template> -->
        <!-- <template v-else>
            <router-link to="/register" class="user-link">注册</router-link>
            <router-link to="/login" class="user-link">登录</router-link>
        </template> -->
    </div>
</template>
<script>
export default {
    name: 'UserInfo',
    props: ['userInfoObj'],
    methods: {
        goUserCenter() {
            // console.log('router', this.$route)
            if (this.$route.fullPath !== '/my') {
                this.$router.push('/my')
            }
        }
    }
}
</script>
<style lang="scss" scoped>
    .user-wrapper {
        @include inlineBlock;
        padding: 0 10px;
        height: 100%;
        text-align: right;
        .item {
            @include inlineBlock;
            width: 50px;
            height: 50px;
            padding: 13px;
        }
    }
    .avatar-box {
        position: relative;
        .icon {
            position: absolute;
            top: 10px;
            right: 10px;
            width: 16px;
            height: 16px;
            line-height: 16px;
            border-radius: 50%;
            text-align: center;
            color: $white;
            background-color: $red;
        }
        .avatar {
            position: static;
            display: block;
            width: 24px;
            height: 24px;
            border-radius: 3px;
            background-color: transparent;
        }
    }
    .user-link {
        @include inlineBlock();
        padding: 0 25px;
        margin: 10px 0;
        line-height: 30px;
        border-left: 1px solid $gray;
        &:first-child {
            border: none;
        }
    }
    .user-popover-item {
        display: block;
        padding: 5px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .user-info-simple {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        padding: 0;
        text-align: right;
        .user-link {
            @include inlineBlock();
            padding: 0 30px;
            margin: 5px 0;
            line-height: 20px;
            border-left: 1px solid $gray;
            &:first-child {
                border: none;
            }
        }
    }
</style>
