<template>
    <header class="header" :class="{ 'is-login': userInfo }">
        <div class="header-content">
            <div class="header-inner">
                <section class="logo">
                    <router-link to="/">
                        <img class="logo" :src="require('@a/img/logo.png')" alt="kamisoul">
                    </router-link>
                </section>
                <div class="nav-wrapper">
                    <ul class="nav-list">
                        <li>
                            <router-link to="/">
                                <i class="icon" :class="{ 'icon-home-normal': $route.fullPath!=='/', 'icon-home-hover': $route.fullPath==='/' }" />
                                <span class="txt">首页</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/news">
                                <i class="icon" :class="{ 'icon-zixun-normal': $route.fullPath!=='/news', 'icon-zixun-hover': $route.fullPath==='/news' }" />
                                <span class="txt">资讯</span>
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/product">
                                <i class="icon" :class="{ 'icon-product-normal': $route.fullPath!=='/product', 'icon-product-hover': $route.fullPath==='/product' }" />
                                <span class="txt">产品</span>
                            </router-link>
                        </li>
                        <!-- <li>
                            <router-link to="/introduction">
                                <i class="icon" :class="{ 'icon-about-normal': $route.fullPath!=='/introduction', 'icon-about-hover': $route.fullPath==='/introduction' />
                                <span class="txt">介绍</span>
                            </router-link>
                        </li> -->
                    </ul>
                </div>
                <user-info :user-info-obj="userInfo" @toggle-search-drop="toggleSearchDrop" @toggle-uc-drop="toggleUcDrop" />
            </div>
        </div>
        <page-search-drop ref="searchDrop" />
        <page-uc-drop ref="ucDrop" :user-info-obj="userInfo" />
    </header>
</template>
<script>
import userInfo from './userInfo'
import pageSearchDrop from './pageSearchDrop'
import pageUcDrop from './pageUcDrop'
export default {
    name: 'PageHeader',
    components: {
        userInfo,
        pageSearchDrop,
        pageUcDrop
    },
    props: {
        userInfo: {
            type: Object
        }
    },
    data() {
        return {
            
        }
    },
    methods: {
        toggleSearchDrop() {
            this.$nextTick(_ => {
                this.$refs.searchDrop.showDrop = !this.$refs.searchDrop.showDrop
            })
        },
        toggleUcDrop() {
            this.$nextTick(_ => {
                this.$refs.ucDrop.showDrop = !this.$refs.ucDrop.showDrop
            })
            // 下拉之前更新下用户信息
            // if(this.$refs.ucDrop.showDrop) {
            //     this.getCacheUserInfo()
            // }
        },
    }
}
</script>
<style lang="scss" scoped>
    .header {
        position: relative;
        min-width: 1200px;
        z-index: 100;
        background-color: $white;
    }
    .header-content {
        position: relative;
        z-index: 100;
        min-width: 1200px;
        height: 50px;
        background-color: $white;
    }
    .header-inner {
        margin: 0 auto;
        width: 1200px;
        height: 100%;
        border-bottom: 1px solid $gray;
        font-size: 14px;
    }
    .logo {
        width: 180px;
        height: 100%;
        @include inlineBlock;
    }
    .nav-wrapper {
        @include inlineBlock;
        margin: 0 50px 0 90px;
        width: calc(100% - 440px);
        height: 100%;
    }
    .nav-list {
        li {
            @include inlineBlock;
            // width: calc((100% - 100px) / 4);
            width: 150px;
            a {
                display: inline-block;
                padding: 0 20px;
                @include lineHeight(50px);
                .txt {
                    @include inlineBlock;
                    padding: 0 10px;
                }
            }
        }
    }
    // .is-login {
    //     .nav-wrapper {
    //         width: calc(100% - 440px);
    //     }
    // }
</style>
