<template>
    <section class="uc-drop" :class="{ active: showDrop }">
        <div v-if="userInfoObj" class="uc-drop-item" @mouseenter="showDrop=true" @mouseleave="showDrop=false">
            <ul class="uc-drop-list">
                <li class="uc-drop-logan">
                    <p class="uc-logan"><img :src="require('@a/img/KAMISOUL-white-100.png')" alt="KAMISOUL"></p>
                    <p class="uc-logan-tips">Toys For Collectors</p>
                </li>
                <li class="uc-title-wrapper">
                    <section class="uc-box">
                        <div class="head-title">
                            <i class="icon icon-more" />
                            <i class="icon icon-user" />
                            <span class="head-title-txt">MySoul<strong>个人中心</strong></span>
                            <h3 v-if="userInfoObj.status===2" class="box-round head-tips">TIPS：用户名违规，请<a href="javascript:;" @click.prevent="$router.push('/my/accout')">立即修改</a></h3>
                        </div>
                        <div class="head-num">
                            <a class="head-num-item" @click="gotoPage(3)">
                                待付款<i class="head-num-num">{{ userInfoObj && userInfoObj.daifukuan }}</i>
                            </a>
                            <a class="head-num-item" @click="gotoPage(4)">
                                待发货<i class="head-num-num">{{ userInfoObj && userInfoObj.daifahuo }}</i>
                            </a>
                            <router-link class="head-num-item" to="/my/message">
                                我的消息<i class="head-num-num">{{ userNews }}</i>
                            </router-link>
                        </div>
                    </section>
                </li>
                <li class="uc-user">
                    <div class="head-box">
                        <img class="head-img" :src="userInfoObj.headimgurl || require('@a/img/head.jpg')" alt="">
                        <span class="head-name text-ellipsis">{{ userInfoObj && userInfoObj.username }}</span>
                        <span class="head-accout">{{ userInfoObj && userInfoObj.phone }}</span>
                        <p class="uc-user-toolbar">
                            <router-link to="/my/accout" class="uc-user-btn">
                                <i class="icon icon-more-w" />
                                <span>账户设置</span>
                            </router-link>
                            <a href="javascript:;" class="uc-user-btn" @click="logout">
                                <i class="icon icon-more-w" />
                                <span>退出登录</span>
                            </a>
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div v-else class="uc-drop-item" @mouseenter="showDrop=true" @mouseleave="showDrop=false">
            <ul class="uc-drop-list uc-drop-list2">
                <li class="uc-title-wrapper">
                    <section class="uc-box">
                        <div class="head-title">
                            <i class="icon icon-more" />
                            <span class="head-title-txt">MySoul<strong>会员</strong></span>
                            <i class="icon icon-user" />
                        </div>
                        <div class="head-num">
                            <p>成为会员可以第一时间关注并购买到kamisoul产品，这些产品仅限会员购买</p>
                        </div>
                    </section>
                </li>
                <li class="uc-drop-logan">
                    <p class="uc-logan"><img :src="require('@a/img/KAMISOUL-white-100.png')" alt=""></p>
                    <p class="uc-logan-tips">Toys For Collectors</p>
                </li>
                <li class="uc-user">
                    <p class="uc-user-toolbar">
                        <router-link to="/login" class="uc-user-btn">
                            <span>登录</span>
                        </router-link>
                        <router-link to="/register" class="uc-user-btn">
                            <span>注册</span>
                            <small>成为kamisoul会员</small>
                        </router-link>
                    </p>
                </li>
            </ul>
        </div>
        <div v-show="showDrop" class="page-mask" />
    </section>
</template>
<script>
import { logout } from '@api'
export default {
    name: 'PageUcDrop',
    props: ['userInfoObj'],
    data() {
        return {
            showDrop: false
        }
    },
    computed: {
        userNews() {
            const userInfoObj = this.userInfoObj
            const newStr = userInfoObj ? +userInfoObj.news > 99 ? '99+' : +userInfoObj.news : 0
            return newStr
        }
    },
    methods: {
        gotoPage(type) {
            this.showDrop = false
            this.$router.push(`/my/order/${type}`)
        },
        async logout() {
            const res = await logout()
            if (res.detail.status === '1') {
                // console.log(222222)
                this.isLogin = false
                this.$utils.clearUser()
                location.reload(true)
            }
            // this.$notify({ title: '提示', type: res.detail.status === '1' ? 'success' : 'error', message: res.msg, position: 'bottom-right' })
            // this.$message({ type: res.detail.status === '1' ? 'success' : 'error', message: res.msg })
        }
    }
    // mounted() {
    //     const self = this
    //     this.$nextTick(_ => {
    //         document.querySelector('body').addEventListener('click', function() {
    //             console.log('body click')
    //             self.showDrop = false
    //         })
    //     })
    // }
}
</script>
<style lang="scss" scoped>
.uc-drop {
    position: absolute;
    z-index: 8;
    top: -500px;
    // top: 50px;
    left: 0;
    width: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, .6);
    transition: top .3s;
    &.active {
        top: 50px;
    }
}
.uc-drop-item {
    position: relative;
    z-index: 8;
    height: 200px;
    padding: 20px 0;
    background-color: $reder;
    &:last-child {
        margin-bottom: 0;
    }
}
.uc-drop-list {
    height: 100%;
    overflow: hidden;
    > li {
        @include inlineBlock();
        width: 340px;
        height: 100%;
        &:nth-child(1) {
            padding: 15px;
        }
        &:nth-child(2) {
            width: 510px;
        }
    }
}
.uc-logan {
    margin: 0 auto;
    padding: 20px 0 10px;
    width: 140px;
    border-bottom: 2px solid $white;
}
.uc-logan-tips {
    margin-top: 10px;
    font-weight: bold;
    font-size: 14px;
    color: $white;
}
.uc-title-wrapper {
    padding: 0 15px;
    border-right: 2px dashed $lightest;
}
.uc-box {
    margin-bottom: 10px;
    background-color: $white;
    padding: 30px;
    border-radius: 2px;
}
.head-title {
    position: relative;
    padding-bottom: 20px;
    text-align: left;
    border-bottom: 1px dashed $lighter;
    .icon {
        margin-right: 10px;
    }
}
.head-tips {
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    right: 0;
    width: 240px;
}
.head-title-txt {
    @include inlineBlock();
    margin-left: 5px;
    font-weight: bold;
    font-size: 15px;
    color: $red;
    strong {
        display: block;
        font-weight: bolder;
        font-size: 18px;
    }
}
.head-num {
    padding-top: 10px;
    text-align: center;
    color: $light;
}
.head-num-item {
    @include inlineBlock();
    margin: 0 49px;
    color: $light;
    cursor: pointer;
}
.head-num-num {
    display: block;
    margin-top: 2px;
    font-weight: 700;
    font-size: 14px;
    color: $red;
}
.head-box {
    position: relative;
    height: 100%;
    margin-left: 15px;
    padding: 30px 20px;
    text-align: left;
    border-radius: 2px;
    color: $white;
    background-color: $redest;
    > * {
        display: block;
        margin: 0 auto 5px;
    }
}
.head-img {
    position: absolute;
    left: 30px;
    top: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}
.head-name {
    margin-left: 70px;
    font-size: 14px;
}
.head-accout {
    @include inlineBlock();
    padding: 5px 10px;
    margin: 0 0 0 70px;
    border-radius: 20px;
    background-color: $reddark;
}
.uc-user-toolbar {
    margin: 20px 0 0;
    text-align: center;
}
.uc-user-btn {
    @include inlineBlock();
    margin-right: 30px;
    padding: 8px 30px 8px 10px;
    color: $gray;
    background-color: $reddark;
    &:hover {
        color: $white;
    }
    &:last-child {
        margin-right: 0;
    }
    > * {
        @include inlineBlock();
    }
    .icon {
        margin-right: 20px;
    }
}
// 第二行
.uc-drop-list2 {
    > li {
        &:nth-child(1) {
            padding: 0 15px;
        }
    }
    .head-num {
        text-align: left;
        line-height: 1.5;
    }
    .head-title .icon {
        margin-right: 5px;
    }
    .head-title-txt {
        margin-right: 130px;
    }
    .uc-logan {
        margin-top: 20px;
    }
    .uc-user {
        padding-left: 15px;
        border-left: 2px dashed $lightest;
    }
    .uc-user-toolbar {
        margin: 0;
    }
    .uc-user-btn {
        height: 75px;
        padding: 25px 0;
        display: block;
        margin: 0 0 10px;
        font-weight: bold;
        font-size: 16px;
        color: $gray;
        background-color: $redest;
        &:hover {
            color: $white;
        }
        > small {
            display: block;
        }
        &:last-child {
            padding: 18px 0;
        }
    }
}

</style>
