<template>
    <section class="search-drop" :class="{ active: showDrop }">
        <div class="search-drop-in" @mouseenter="onMouseenter" @mouseleave="onMouseleave">
            <section class="search-drop-content">
                <el-input v-model="title" placeholder="请输入内容" class="input-with-select" clearable>
                    <el-select slot="prepend" v-model="searchType" :popper-append-to-body="false" placeholder="搜索类型">
                        <el-option label="产品" :value="1" />
                        <el-option label="资讯" :value="2" />
                    </el-select>
                    <el-button slot="append" @keyup.enter.native="onSearch" @click="onSearch">搜索</el-button>
                </el-input>
            </section>
        </div>
        <div v-show="showDrop" class="page-mask" />
    </section>
</template>
<script>
// import { getInformationList } from '@api/news'
// import { getCommodityList } from '@api/product'
export default {
    name: 'PageSearchDrop',
    data() {
        return {
            showDrop: false,
            title: '',
            searchType: 1,
            timer: null
        }
    },
    methods: {
        clearTimer() {
            if(this.timer) {
                window.clearTimeout(this.timer)
                this.timer = null
            }
        },
        onMouseenter() {
            this.clearTimer()
            this.showDrop = true
        },
        onMouseleave() {
            const self = this
            this.clearTimer()
            this.timer = setTimeout(_ => {
                if(self.timer) self.showDrop = false
            }, 500)
        },
        onSearch() {
            console.log('this.$route', this.$route)
            const path = this.$route.path
            if (this.searchType === 1) {
                this.$router.push(`/product?title=${this.title}`)
            } else if (this.searchType === 2) {
                this.$router.push(`/news?title=${this.title}`)
            }
        }
    }
    // mounted() {
    //     const self = this
    //     this.$nextTick(_ => {
    //         document.querySelector('body').addEventListener('click', function() {
    //             console.log('body click')
    //             self.showDrop = false
    //         })
    //     })
    // }
}
</script>
<style lang="scss" scoped>
    .search-drop {
        position: absolute;
        z-index: 99;
        top: -115px;
        left: 0;
        width: 100%;
        height: 90px;
        text-align: center;
        border-bottom: 1px solid $grayest;
        background-color: $white;
        transition: top .3s;
        &.active {
            top: 50px;
        }
    }
    .search-drop-in {
        position: relative;
        z-index: 8;
        padding: 20px 0;
        background-color: $white;
    }
    .search-drop-content {
        width: 600px;
        display: inline-block;
    }
    /deep/ .el-select .el-input {
        width: 130px;
    }
    /deep/ .input-with-select .el-input-group__prepend {
        background-color: $white;
    }
</style>
